
import { ProductRecorderView, ReviewsAPIPublic } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';

import PoweredByOkendo from '@/shared-components/PoweredByOkendo.vue';
import ProductImage from '@/shared-components/ProductImage.vue';
import Profile from '@/shared-components/profile/Profile.vue';
import Terms from '@/shared-components/Terms.vue';
import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { postAnalytics, postQuestion } from '@/utils/api';
import { isEmailAddress, scrollToFirstErrorElement, validateRules } from '@/utils/validationUtil';

@Options({
    components: {
        Profile,
        PoweredByOkendo,
        ProductImage,
        Terms
    },
    emits: {
        'formSubmitted': (v: boolean) => typeof v === 'boolean'
    },
    store
})
export default class ReviewForm extends Vue {
    hasSubmitted = false;
    isSending = false;
    questionBody = '';

    get product(): ProductRecorderView {
        return store.state.product.product;
    }

    get questionPlaceholderText(): string {
        const productName = this.product.name || 'this product';
        return this.$t('What would you like to know about {productName}?', { productName });
    }

    get isTestMode(): boolean {
        return store.state.order.reviewRequestId === 'test';
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    isQuestionFormValid(): boolean {
        const { reviewerProfile: { name, email } } = store.state.profile;

        const areFieldsValid: Record<string, boolean> = {
            'Please enter a <strong>Question</strong>': !!this.questionBody,
            'Please enter your <strong>Name</strong>': !!name,
            'Please enter your <strong>Email Address</strong>': isEmailAddress(email)
        };

        return validateRules(areFieldsValid);
    }

    async submitQuestionForm(): Promise<void> {
        if (this.isSending || this.isPreviewMode) {
            return;
        }

        this.hasSubmitted = true;
        if (!this.isQuestionFormValid()) {
            scrollToFirstErrorElement();
            return;
        }

        this.isSending = true;

        const productId = this.product.productId;
        const { profile: { reviewerProfile } } = store.state;

        const questionData: ReviewsAPIPublic.Questions.Post.Request = {
            question: {
                subscriberId: store.state.subscriber.subscriberId,
                productId,
                body: this.questionBody,
                author: {
                    name: reviewerProfile.name || '',
                    email: (reviewerProfile.email || '').trim(),
                    socialConnection: reviewerProfile.socialMediaType,
                    avatarUrl: reviewerProfile.imageUrl
                },
                isTestQuestion: this.isTestMode
            }
        };

        try {
            const { question: questionResponse } = await postQuestion(questionData);
            postAnalytics({
                eventName: 'action-submitted-question',
                label: 'questionId',
                value: questionResponse.questionId
            });

            this.$emit('formSubmitted', true);
        }
        catch {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Question could not be submitted, please try again'),
                    status: 'fail'
                }
            });
        }

        this.isSending = false;
    }
}
